<template>
	<div class="mtipoequipamientoFormHeader puiformheader">
		<v-row>
			<v-col cols="12">
				<pui-field :label="$t('header.mtipoequipamiento.idtipoequip')" :value="getIdtipoequipValue"></pui-field>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<pui-field :label="$t('header.mtipoequipamiento.codtipequipedi')" :value="getCodtipequipediValue"></pui-field>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<pui-field :label="$t('header.mtipoequipamiento.name')" :value="getNameValue"></pui-field>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import PuiFormHeaderMixin from 'pui9-mixins/PuiFormHeaderMixin';

export default {
	name: 'mtipoequipamiento-form-header',
	mixins: [PuiFormHeaderMixin],
	data() {
		return {
			modelName: 'mtipoequipamiento'
		};
	},
	computed: {
		getIdtipoequipValue() {
			return this.model && this.model.idtipoequip ? this.model.idtipoequip : '-';
		},
		getCodtipequipediValue() {
			return this.model && this.model.codtipequipedi ? this.model.codtipequipedi : '-';
		},
		getNameValue() {
			return this.model && this.model.name ? this.model.name : '-';
		},
	}
}
</script>
